import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f37f9900"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cont-wrap" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["src", "srcset"]
const _hoisted_4 = { class: "text-xl" }
const _hoisted_5 = ["src", "srcset"]
const _hoisted_6 = { class: "solution-description" }
const _hoisted_7 = { class: "text-2xl my-4" }
const _hoisted_8 = { class: "text-base mb-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.iconPath,
        srcset: `${_ctx.iconPath}, ${_ctx.iconPath2x} 2x`,
        alt: "icon-solution",
        class: "mr-2"
      }, null, 8, _hoisted_3),
      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.subTitle), 1)
    ]),
    _createElementVNode("img", {
      src: _ctx.imagePath,
      srcset: `${_ctx.imagePath}, ${_ctx.imagePath2X} 2x`,
      alt: "img-solution",
      class: "solution-image my-6"
    }, null, 8, _hoisted_5),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.description), 1)
    ])
  ]))
}
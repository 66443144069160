
import { defineComponent } from "vue";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";

export default defineComponent({
  name: "SolutionContainer",
  components: { AppLink },
  props: {
    subTitle: {
      type: String,
      require: true
    },
    title: {
      type: String,
      require: true
    },
    description: {
      type: String,
      require: true
    },
    url: {
      type: String,
      default: "/"
    },
    icnName: {
      type: String,
      required: true
    },
    imgName: {
      type: String,
      required: true
    },
    isReverse: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const iconPath = `/resource/icons/icn-solution-${props.imgName}.png`;
    const iconPath2x = `/resource/icons/icn-solution-${props.imgName}-2x.png`;
    const imagePath = `/resource/images/img-solution-${props.imgName}.png`;
    const imagePath2X = `/resource/images/img-solution-${props.imgName}-2x.png`;
    return { iconPath, iconPath2x, imagePath, imagePath2X };
  }
});
